<template>
  <lottie-player
    src="ExclamationAnimation.json"
    background="transparent"
    speed="1"
    style="width: 100%; height: auto;"
    autoplay
  >
  </lottie-player>
</template>
<script>
export default {
  name: 'ExclamationAnimation'
}
</script>
